import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/shared/costant';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  clicked: boolean = false;
  versionString : string = "";

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notifications: NotificationService,
    private router: Router) {
    if (this.authService._token) {
      this.router.navigateByUrl('')
    }
  }

  ngOnInit() {
    this.setForm()
    this.versionString = Constant.APP.VERSION_STRING

  }

  getBackgroundUrl(){
    return `/assets/img/${environment.company_image}` ;
  }

  setForm(): void {
    this.loginForm = this.fb.group({
      userNameCtrl: ['', Validators.required],
      passwordCtrl: ['', Validators.required]
    })
  }

  checkAuth(): boolean {

    if (this.loginForm.invalid)
      return false

    /**
     * Uncomment when ignore authentication
     */
    // this.alternativeLogin()

    this.clicked = true;
    let user = new User();
    user.id = 1
    user.username = this.loginForm.controls.userNameCtrl.value
    user.password = this.loginForm.controls.passwordCtrl.value
    let res = this.authService.authenticate(user).subscribe(res => {
      this.authService.setToken(res.token)
      this.authService.setUser(res.user)
      this.router.navigateByUrl('')
      location.href = ''
    }, error => {
      this.clicked = false;
      this.notifications.showWarning("Invalid Credentials..!")
    });

  }

  alternativeLogin() {
    this.authService.setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJyb2hhbkBicnQuY29tIiwibmFtZSI6InJvaGFuIiwidXNlcl9yb2xlX2lkIjoxLCJ1c2VybmFtZSI6Indtcm9oYW4iLCJpYXQiOjE1OTY4NTkyODIsImV4cCI6MTU5Njg3NzI4Mn0.lfu5Ay8jC3PNqnheM0wumJgsazrQu3j_lCy3Rojm9WU")
    this.router.navigateByUrl('')
    location.href = ''
  }


}
