import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  usearData: any = null;
  
  constructor(
    private translate: TranslateService,
    public globals: ThemeOptions, 
    private authService: AuthService, 
    private router: Router) {
  }

  ngOnInit() {
    this.usearData = JSON.parse(this.authService.getUser())
  }

  logOut(){
    this.authService.logOut()
  }

  changeLanguage(lang: string){
    this.translate.use(lang);
  }

  loadMyProfile(){
    this.router.navigate(['profile/my'])
  }

}
