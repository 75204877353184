import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from '../shared/api-constant';

@Injectable({
  providedIn: 'root'
})
export class DebitAdviseService {

  constructor(private httpClient: HttpClient) { }

  getAllDebitAdvise(page: number = 1,debit_type: string = 'D', param: string = ""): Observable<any> {
    return this.httpClient.get(Api.API_URL.DEBIT_ADVISES.PREFIX+ '/pagination/'+page+'?debit_type='+debit_type+'&total_outstanding_amount={ "gte": 0}' + param);
  }

  getDebitAdviseBy(id: number): Observable<any> {
    return this.httpClient.get(Api.API_URL.DEBIT_ADVISES.PREFIX+ '/'+id);
  }

  getDebitAdvise(query: string): Observable<any>{
    return this.httpClient.get(Api.API_URL.DEBIT_ADVISES.PREFIX + query);
  }

  searchDebitAdviceBy(query: string, debit_type: string = 'D'): Observable<any> {
    return this.httpClient.get(Api.API_URL.DEBIT_ADVISES.PREFIX + '?debit_type='+debit_type+'&total_outstanding_amount={ "gte": 0}'+ query);
  }

  saveDebitAdvice(data: any): Observable<any>{
    return this.httpClient.post(Api.API_URL.DEBIT_ADVISES.PREFIX, data);
  }

  updateDebitAdvice(data: any): Observable<any>{
    return this.httpClient.put(Api.API_URL.DEBIT_ADVISES.PREFIX + '/' + data.id, data);
  }

  deleteDebitAdvice(id: number): Observable<any>{
    return this.httpClient.delete(Api.API_URL.DEBIT_ADVISES.PREFIX + '/' + id);
  }

  updateDebitAdviceDirect(data: any): Observable<any>{
    return this.httpClient.put(Api.API_URL.DEBIT_ADVISES.PREFIX+ Api.API_URL.DEBIT_ADVISES.DIRECT + '/' + data.id, data);
  }

  preSettlement(data: any): Observable<any>{
    return this.httpClient.post(Api.API_URL.DEBIT_ADVISES.PREFIX + '/' + Api.API_URL.DEBIT_ADVISES.PRE_SETTLEMENT, data);
  }

  settlement(data: any): Observable<any>{
    return this.httpClient.post(Api.API_URL.DEBIT_ADVISES.PREFIX + '/' + Api.API_URL.DEBIT_ADVISES.SETTLEMENT, data);
  }

}
