/*
-------------------------------------------
// UDARA CHAMARA HERATH
// ASSOCIATE SOFTWARE ENGINEER
// CO-OPERATIVE INSURANCE COMPANY LIMITED
-------------------------------------------
*/

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {

  @Input() Config;
  public Header: string;
  public NavIcon: string;
  public MenuLinks: Array<any>;

  constructor() { }

  ngOnInit() {
    this.Header = this.Config[0].header;
    this.NavIcon = this.Config[0].icon;
    this.MenuLinks = this.Config[0].menuLinks;
  }

}
