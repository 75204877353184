import { Injectable } from '@angular/core';
import { Api } from '../shared/api-constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getAllUser(): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.USER.PREFIX);
  }

  getAllRole(): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.USER_ROLES.PREFIX);
  }

  getUserById(id: number): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.USER.PREFIX + '/' + id);
  }

  getUsersByPage(page: number): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.USER.PREFIX + Api.API_URL.PAGINATION + page);
  }

  getUserBySearch(prefix: string, value: any): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.USER.PREFIX + '?'+prefix+'=%'+value+'%');
  }

  saveUser(data: any): Observable<any>{
    return this.httpClient.post(Api.API_URL.USER.PREFIX, data)
  }

  updateUser(data: any): Observable<any>{
    return this.httpClient.put(Api.API_URL.USER.PREFIX + '/' + data.id, data)
  }

  deleteUser(id: number): Observable<any>{
    return this.httpClient.delete<any>(Api.API_URL.USER.PREFIX + '/' + id);
  }

  resetPassword(data: any):Observable<any>{
    return this.httpClient.post(Api.API_URL.USER.PREFIX + Api.API_URL.USER.RESET_PASSWORD, data)
  }
  
}
