import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, pipe, EMPTY } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/authentication/auth.service';
import { catchError } from 'rxjs/operators'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newHeaders = new HttpHeaders();
    // add authorization header with jwt token if available
    const token = this.authService.getToken();

  // newHeaders = newHeaders.set('Access-Control-Allow-Origin', '*')
  // .set('Content-Type', 'application/json');

    if (token) {     
      newHeaders = newHeaders.set('Authorization', `Bearer ${token}`);
    }

    let newurl = request.url;

    if(request.url == '/assets/i18n/en.json'){
      newurl = `${request.url}`
    }else if(request.url == '/assets/i18n/si.json'){

    }
    else{
      newurl = `${environment.BASE_URL}/${environment.version}/${request.url}`;
    }

    const reqClone = request.clone({
      url: newurl,
      headers: newHeaders,
    });
    
    return next.handle(reqClone).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          // console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          if(error.status == 403){
            this.authService.logOut()
            return next.handle(reqClone)
          }else{
            return next.handle(reqClone)
          }
        }
      })
      )
    
  }
}
