import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Api } from 'src/app/shared/api-constant';
import { Observable } from 'rxjs';
import { Constant } from 'src/app/shared/costant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _token: string = null;
  _user: any = null;
  getAuthTokenUrl: string = Api.API_URL.GET_TOKEN

  constructor(private httpClient: HttpClient, private router: Router) {
    this.getToken()
  }

  getToken() {
    this._token = localStorage.getItem(Constant.APP.TOKEN)
    return this._token
  }

  setToken(token: string) {
    this._token = token;
    localStorage.setItem(Constant.APP.TOKEN, token);
  }

  setUser(user: any){
    this._user = user
    localStorage.setItem(Constant.APP.USER, JSON.stringify(user));
  }

  getUser() {
    this._user = localStorage.getItem(Constant.APP.USER)
    return this._user
  }

  authenticate(user: User): Observable<any> {
    return this.httpClient.post(this.getAuthTokenUrl, user);
  }

  logOut() {
    localStorage.removeItem(Constant.APP.TOKEN)
    localStorage.removeItem(Constant.APP.USER)
    this.router.navigateByUrl(Constant.APP_URL.AUTH.LOGIN)
  }

}
