import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,private router: Router) { }
  
  canActivate(): boolean {
    if(!this.validateToken()){
      return false
    }else{
      return true
    }
  }

  validateToken(){
    let token = this.authService.getToken()
    return token
  }
}
