import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { Constant } from 'src/app/shared/costant';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: Constant.APP_URL.AUTH.LOGIN_ROUTE,
        component: LoginComponent
      },
      {
        path: Constant.APP_URL.AUTH.RECOVER_PASSWORD,
        component: PasswordRecoveryComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
