import { Component, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/authentication/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Rapid Education & Migration Consultants ';

  constructor(private translate: TranslateService, public authService: AuthService, private router: Router) {
    let user = JSON.parse(this.authService.getUser())
    if(user){
      let language = user.language
      translate.addLangs(['en','si']);
      translate.setDefaultLang('en');
      translate.use(language);
    }
   
    
    if(this.authService.getToken()===null){
      this.router.navigateByUrl('auth/login')
    }
  }
}
