export class Constant {

    static APP = {
        TOKEN: 'app_token#LAK',
        USER: 'app_user#LAK',
        VERSION_STRING : 'Copyright RAPID-MIGRATION © 2024 All rights reserved | v1.07.2'
    }

    static MONTH_NAMES : Array<any> = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

    static PAYMENT_TYPES : Array<any> = [{key: 'ADVANCE PAYMENT', value: 'AD'},{key: 'DEBIT SETTLEMENT', value: 'DS'}];

    static PAYMENT_MODES : Array<any> = [{key: 'CHEQUE', value: 'CHEQUE'},{key: 'CASH', value: 'CASH'}];

    static CURRENCIES : Array<any> = [{key: 'LKR', value: 'LKR'},{key: 'USD', value: 'USD'}];

    static DEBIT_TYPES : Array<any> = [{key: 'Debit', value: 'D'},{key: 'Credit', value: 'C'}];

    static TRANSACTION_TYPES : Array<any> = [{key: 'New Business', value: 'N'},{key: 'Renewal', value: 'R'},{key: 'Endorsement', value: 'E'},{key: 'Other', value: 'O'}];

    static CLAIM_STAGE : Array<any> = [{key: 'Intimation', value: 'I'},{key: 'Registration', value: 'R'},{key: 'Documentation', value: 'D'},{key: 'Inspection', value: 'C'},{key: 'Payment', value: 'P'}];

    static RECEIPT_TYPE : Array<any> = [{key: 'RECEIPT', value: 'R'},{key: 'MISCELLANEOUS RECEIPT', value:'M'}]

    static DATA_TABLE_OPTION = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
          buttons: [
              'copy', 'csv', 'excel', 'print'
          ]
      };

    static PERSONAL_CUSTOMER_TYPE = {
        CUSTOMER: {
            KEY : 'CUSTOMER',
            VALUE : 'is_customer'
        },
        BPO : {
            KEY : 'BPO',
            VALUE : 'is_bpo'
        },
        EMPLOYEE : {
            KEY : 'EMPLOYEE',
            VALUE : 'is_employee'
        }
       
    }

    static CORPORATE_CUSTOMER_TYPE = {
        CUSTOMER: {
            KEY : 'CUSTOMER',
            VALUE : 'is_co_customer'
        },
        LEASING : {
            KEY : 'LEASING',
            VALUE : 'is_leasing'
        },
        INSURANCE: {
            KEY : 'INSURANCE',
            VALUE : 'is_insurance'
        },
        BANK: {
            KEY : 'BANK',
            VALUE : 'is_bank'
        },
    }

   
    static DATE_FORMAT = {
        SAVE_DATE_FORMAT : 'yyyy-MM-dd',
        READ_DATE_FORMAT : "YYYY-MM-DD",
    }

    static DOC_TYPE = {
        COVER_NOTE_ID: 13
    }

    static PAY_MODE = {
        ADVANCE_PAYMENT : 'AD',
        DEBT_SETTLEMENT : 'DS',
    }

    static COMPANY_TYPE = {
        INSURANCE : 'I',
        BANK : 'B',
        LEASING : 'L'
    }

    static BUSINESS_CATEGORY = {
        GENERAL_INSURANCE : 'G',
        LIFE_INSURANCE : 'L',
        REINSURANCE : 'R'
    }

    static APP_OBJECT = {
        SETTING: 'setting',
        AUTH: 'auth',
        PROFILE: 'profile',
        ADMIN: 'admin',
        MOTOR: 'motor',
        LIFE: 'life',
        NON_MOTOR: 'non-motor',
        OPERATION_REPORTS: 'operation-reports',
        ACCOUNTING: 'accounting',
        FINANCE: 'finance',
        FINANCE_REPORT: 'finance-report',
        RECEIPT: 'receipt'
    }

    static USER_ROLE = {
        ADMIN: 1,
        COMPANY_ADMIN: 2,
        USER: 3,
        SUPER_ADMIN : 4
    }

   
    static APP_URL = {
        SETTING: {
            COVER_ROUTE: 'cover',
            COVER: Constant.APP_OBJECT.SETTING + '/cover',
            PRODUCT_ROUTE: 'product',
            PRODUCT: Constant.APP_OBJECT.SETTING + '/product',
            
            BUSSINESS_CLASS_ROUTE: 'bussiness-class',
            BUSSINESS_CLASS: Constant.APP_OBJECT.SETTING + '/bussiness-class',
            
            BUSSINESS_CATEGORY_ROUTE: 'bussiness-category',
            BUSSINESS_CATEGORY: Constant.APP_OBJECT.SETTING + '/bussiness-category',
        },
        AUTH: {
            LOGIN_ROUTE: 'login',
            LOGIN: Constant.APP_OBJECT.AUTH + '/login',
            RECOVER_PASSWORD: 'recover-password'
        },
        ADMIN: {
            CUSTOMER: Constant.APP_OBJECT.ADMIN + '/customer',
            CUSTOMER_ROUTE: 'customer',
            MANAGE_CUSTOMER: Constant.APP_OBJECT.ADMIN + '/manage-customer/',
            MANAGE_CUSTOMER_ROUTE: 'manage-customer',
            
            CORPORATE_CUSTOMER : Constant.APP_OBJECT.ADMIN + '/corporate-customer',
            CORPORATE_CUSTOMER_ROUTE: 'corporate-customer',
            MANAGE_CORPORATE_CUSTOMER: Constant.APP_OBJECT.ADMIN + '/manage-corporate-customer/',
            MANAGE_CORPORATE_CUSTOMER_ROUTE: 'manage-corporate-customer',
            
            EMPLOYEE: Constant.APP_OBJECT.ADMIN + '/employee',
            EMPLOYEE_ROUTE: 'employee',
            MANAGE_EMPLOYEE: Constant.APP_OBJECT.ADMIN + '/manage-employee/',
            MANAGE_EMPLOYEE_ROUTE: 'manage-employee',

            USER: Constant.APP_OBJECT.ADMIN + '/user',
            USER_ROUTE: 'user',
            MANAGE_USER: Constant.APP_OBJECT.ADMIN + '/manage-user/',
            MANAGE_USER_ROUTE: 'manage-user',

            BPO: Constant.APP_OBJECT.ADMIN + '/bpo',
            BPO_ROUTE: 'bpo',
            MANAGE_BPO: Constant.APP_OBJECT.ADMIN + '/manage-bpo/',
            MANAGE_BPO_ROUTE: 'manage-bpo',


            BPO_INCENTIVE: Constant.APP_OBJECT.ADMIN + '/bpo-incentive',
            BPO_INCENTIVE_ROUTE: 'bpo-incentive',
           
            BUSSINESS_COMMISION: Constant.APP_OBJECT.ADMIN + '/bussiness-class-commision',
            BUSSINESS_COMMISION_ROUTE: 'bussiness-class-commision',

            PRODUCT_COVER_INSURANCE: Constant.APP_OBJECT.ADMIN + 'product-cover-insurance',
            PRODUCT_COVER_INSURANCE_ROUTE: 'product-cover-insurance',
            MANAGE_PRODUCT_COVER_INSURANCE: Constant.APP_OBJECT.ADMIN + 'manage-product-cover-insurance/',
            MANAGE_PRODUCT_COVER_INSURANCE_ROUTE: 'manage-product-cover-insurance',

            ONLINE_PRODUCT: Constant.APP_OBJECT.ADMIN + 'online-product',
            ONLINE_PRODUCT_ROUTE: 'online-product',

            ONLINE_PRODUCT_FACTOR : Constant.APP_OBJECT.ADMIN + 'online-product-factor',
            ONLINE_PRODUCT_FACTOR_ROUTE: 'online-product-factor',
           

        },
        MOTOR: {
            QUOTATION: Constant.APP_OBJECT.MOTOR + '/quotation',
            QUOTATION_ROUTE: 'quotation',
            MANAGE_QUOTATION: Constant.APP_OBJECT.MOTOR + '/manage-quotation',
            MANAGE_QUOTATION_ROUTE: 'manage-quotation',
            GET_QUOTATION: Constant.APP_OBJECT.MOTOR + '/get-quotation',
            GET_QUOTATION_ROUTE: 'get-quotation',
            COMPARE_QUOTATION: Constant.APP_OBJECT.MOTOR + '/compare-quotation',
            COMPARE_QUOTATION_ROUTE: 'compare-quotation',
            POLICY_CONVERT: Constant.APP_OBJECT.MOTOR + '/policy-convert',
            POLICY_CONVERT_ROUTE: 'policy-convert',
            POLICY: Constant.APP_OBJECT.MOTOR + '/policy',
            POLICY_ROUTE: 'policy',
            RENEWAL: Constant.APP_OBJECT.MOTOR + '/renewal',
            RENEWAL_ROUTE: 'renewal',
            RENEWAL_EXTRACTION: Constant.APP_OBJECT.MOTOR + '/renewal-extraction',
            RENEWAL_EXTRACTION_ROUTE: 'renewal-extraction',
            MANAGE_POLICY: Constant.APP_OBJECT.MOTOR + '/manage-policy',
            MANAGE_POLICY_ROUTE: 'manage-policy',
            DEBIT_NOTE: Constant.APP_OBJECT.MOTOR + '/debit-advice-search',
            DEBIT_NOTE_ROUTE: 'debit-advice-search',
            MANAGE_DEBIT_NOTE: Constant.APP_OBJECT.MOTOR + '/manage-debit-note',
            MANAGE_DEBIT_NOTE_ROUTE: 'manage-debit-note',

            CLAIMS : Constant.APP_OBJECT.MOTOR + '/claims',
            CLAIMS_ROUTE: 'claims',
            MANAGE_CLAIMS: Constant.APP_OBJECT.MOTOR + '/manage-claims/',
            MANAGE_CLAIMS_ROUTE: 'manage-claims',

        },
        LIFE: {
            QUOTATION: Constant.APP_OBJECT.LIFE + '/quotation',
            QUOTATION_ROUTE: 'quotation',
            MANAGE_QUOTATION: Constant.APP_OBJECT.LIFE + '/manage-quotation',
            MANAGE_QUOTATION_ROUTE: 'manage-quotation',
            GET_QUOTATION: Constant.APP_OBJECT.LIFE + '/get-quotation',
            GET_QUOTATION_ROUTE: 'get-quotation',
            COMPARE_QUOTATION: Constant.APP_OBJECT.LIFE + '/compare-quotation',
            COMPARE_QUOTATION_ROUTE: 'compare-quotation',
            POLICY_CONVERT: Constant.APP_OBJECT.LIFE + '/policy-convert',
            POLICY_CONVERT_ROUTE: 'policy-convert',
            POLICY: Constant.APP_OBJECT.LIFE + '/policy',
            POLICY_ROUTE: 'policy',
            RENEWAL: Constant.APP_OBJECT.LIFE + '/renewal',
            RENEWAL_ROUTE: 'renewal',
            MANAGE_POLICY: Constant.APP_OBJECT.LIFE + '/manage-policy',
            MANAGE_POLICY_ROUTE: 'manage-policy',
            DEBIT_NOTE: Constant.APP_OBJECT.LIFE + '/debit-advice-search',
            DEBIT_NOTE_ROUTE: 'debit-advice-search',
            MANAGE_DEBIT_NOTE: Constant.APP_OBJECT.LIFE + '/manage-debit-note',
            MANAGE_DEBIT_NOTE_ROUTE: 'manage-debit-note',
            
            CLAIMS : Constant.APP_OBJECT.LIFE + '/claims',
            CLAIMS_ROUTE: 'claims',
            MANAGE_CLAIMS: Constant.APP_OBJECT.LIFE + '/manage-claims/',
            MANAGE_CLAIMS_ROUTE: 'manage-claims',
        },
        PROFILE: {
            MY_PROFILE: Constant.APP_OBJECT.PROFILE + '/my',
            MY_PROFILE_ROUTE: 'my'
        },
        NON_MOTOR: {

        },
        OPERATION_REPORTS: {
            DAILY_REPORTS: Constant.APP_OBJECT.OPERATION_REPORTS + '/daily-reports',
            DAILY_REPORTS_ROUTE: 'daily-reports',
            AGE_ANALYSIS: Constant.APP_OBJECT.OPERATION_REPORTS + '/age-analysis',
            AGE_ANALYSIS_ROUTE: 'age-analysis',
        },
        ACCOUNTING: {

        },
        FINANCE: {
            GENERAL_PAYMENT:Constant.APP_OBJECT.FINANCE + '/general_payment',
            GENERAL_PAYMENT_ROUTE:'general_payment',
            MANAGE_GENERAL_PAYMENTS:Constant.APP_OBJECT.FINANCE + '/manage_general_payments',
            MANAGE_GENERAL_PAYMENTS_ROUTE: 'manage_general_payments',
            EXPENSES:Constant.APP_OBJECT.FINANCE + '/expense',
            EXPENSES_ROUTE:'expense',
            MANAGE_EXPENSE:Constant.APP_OBJECT.FINANCE + '/manage-expences',
            MANAGE_EXPENSE_ROUTE:'manage-expences',
        },
        FINANCE_REPORT: {

        },
        RECEIPT: {
            PREFIX: Constant.APP_OBJECT.RECEIPT  + '',
            
            ADD_RECEIPT: Constant.APP_OBJECT.RECEIPT + '/receipt',
            ADD_RECEIPT_ROUTE: 'receipt',

            MANAGE_RECEIPT: Constant.APP_OBJECT.RECEIPT + '/manage-receipt',
            MANAGE_RECEIPT_ROUTE: 'manage-receipt',

            MISCELLANEOUS_RECEIPT: Constant.APP_OBJECT.RECEIPT + '/miscellaneous-receipt',
            MISCELLANEOUS_RECEIPT_ROUTE: 'miscellaneous-receipt',

            MANAGE_MISCELLANEOUS_RECEIPT: Constant.APP_OBJECT.RECEIPT + '/manage-miscellaneous-receipt',
            MANAGE_MISCELLANEOUS_RECEIPT_ROUTE: 'manage-miscellaneous-receipt',

            REPRINT_RECEIPT: Constant.APP_OBJECT.RECEIPT + '/receipt',
            REPRINT_RECEIPT_ROUTE: 'receipt',
            
            DEBIT: Constant.APP_OBJECT.RECEIPT + '/debit',
            DEBIT_ROUTE: 'debit',
            CREDIT: Constant.APP_OBJECT.RECEIPT + '/credit',
            CREDIT_ROUTE: 'credit',

            DEBIT_SETTLEMENT: Constant.APP_OBJECT.RECEIPT + '/debit-settlement',
            DEBIT_SETTLEMENT_ROUTE: 'debit-settlement',

            MANAGE_DEBIT_SETTLEMENT: Constant.APP_OBJECT.RECEIPT + '/manage-debit-settlement',
            MANAGE_DEBIT_SETTLEMENT_ROUTE: 'manage-debit-settlement',

            DEBIT_BULK_SETTLEMENT: Constant.APP_OBJECT.RECEIPT + '/bulk-debit-settlement',
            DEBIT_BULK_SETTLEMENT_ROUTE: 'bulk-debit-settlement',

           

        }
    }

}