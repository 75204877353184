import { Injectable } from '@angular/core';
import { Api } from '../shared/api-constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }

  getBussinessObjct(query: string): Observable<any>{
    return this.httpClient.get<any>(Api.API_URL.REPORTS.PREFIX + Api.API_URL.REPORTS.BUSSINESS_OBJECT + query);
  }
  
}
