import {Component, HostBinding, Output, EventEmitter, Input} from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { Constant } from 'src/app/shared/costant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  public MenuLinksSettings = [
    {
      label: 'Link 1',
      path: '/settings/address-type'
    }
  ];

  public configData = [
    {
      header: 'SETTINGS',
      icon: 'la la-cog',
     // menuLinks: this.MenuLinksSettings
    }
  ];

  usearData: any = null;
  isAdmin: boolean = false

  constructor(
    public globals: ThemeOptions,
    private authService: AuthService, 
    private router: Router) {
      this.usearData = JSON.parse(this.authService.getUser())
      if(this.usearData){
        
        if(this.usearData.user_role_id == Constant.USER_ROLE.ADMIN || this.usearData.user_role_id == Constant.USER_ROLE.SUPER_ADMIN ){
          this.isAdmin = true;
        }
      }
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  // @select('config') public config$: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  navigateDashboard(){
    this.router.navigate([''])
  }


}
