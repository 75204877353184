import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrOptions } from 'ng6-toastr-notifications/lib/toastr.options';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public  toastrmsg : ToastrOptions;

    TOAST_ELEMENTS = {
      position: 'bottom-right',
      toastTimeout: 2000,
      maxShown: 1,
      newestOnTop: false,
      animate: null,
      enableHTML: false,
      dismiss: 'auto',
      messageClass: '',
      titleClass: '',
      showCloseButton: true
  }

    constructor(public toastr: ToastrManager,private translate:TranslateService) {
        this.toastrmsg = this.TOAST_ELEMENTS;
    }

    // USE MESSAGE //
    showWarningMessage(message: string) {
        this.toastr.warningToastr(this.translate.instant(message), this.translate.instant('WARNING'),this.toastrmsg);
    }

    // SUCCESS
    successSave() {
        this.toastr.successToastr(this.translate.instant('RECORD_CREATED_SUCCESSFULLY'),this.translate.instant('SAVED') ,this.toastrmsg);
    }

    successUpdate() {
        this.toastr.successToastr(this.translate.instant('RECORD_UPDATED_SUCCESSFULLY'),this.translate.instant('UPDATED') ,this.toastrmsg);
    }
    
    successDelete() {
        this.toastr.successToastr(this.translate.instant('RECORD_DELETED_SUCCESSFULLY'),this.translate.instant('DELETED') ,this.toastrmsg);
    }
    successAdd() {
        this.toastr.successToastr(this.translate.instant('RECORD_ADD_SUCCESSFULLY'),this.translate.instant('ADDED') ,this.toastrmsg);
    }

    // ERROR
    errSave() {
        this.toastr.errorToastr(this.translate.instant('AN_ERROR_OCCURRED._RECORD_CREATION_FAILED'),this.translate.instant('AN_ERROR_OCCURRED') ,this.toastrmsg);
    }

    errOccourred() {
        this.toastr.errorToastr(this.translate.instant('AN_ERROR_OCCURRED._TRY_AGAIN'),this.translate.instant('AN_ERROR_OCCURRED') ,this.toastrmsg);
    }

    errUpdate() {
        this.toastr.errorToastr(this.translate.instant('AN_ERROR_OCCURRED._RECORD_UPDATE_FAILED'),this.translate.instant('AN_ERROR_OCCURRED') ,this.toastrmsg);
    }

    errDelete() {
        this.toastr.errorToastr(this.translate.instant('AN_ERROR_OCCURRED._RECORD_DELETE_FAILED'),this.translate.instant('AN_ERROR_OCCURRED') ,this.toastrmsg);
    }

    errAdd() {
        this.toastr.errorToastr(this.translate.instant('AN_ERROR_OCCURRED._RECORD_ADD_FAILED'),this.translate.instant('ADDED') ,this.toastrmsg);
    }

    searchError(message: string) {
        this.toastr.warningToastr(message, this.translate.instant('INFO'),this.toastrmsg);
    }

    // UNKNOWN WARNING
    showWarning(message: string) {
        this.toastr.warningToastr(message, this.translate.instant('CALL_SYSTEM_ADMINISTRATOR'),this.toastrmsg);
    }

    // INFORMATION MESSAGES
    showSearchInfo(message: string){
        this.toastr.infoToastr(message, this.translate.instant('INFO'),this.toastrmsg);
    }
    
    showSuccessCustomMessage(message: string) {
        this.toastr.successToastr(message,this.translate.instant('SUCCESS') ,this.toastrmsg);
    }

    // INFORMATION MESSAGES
    informationMessages(message: string) {
        this.toastr.errorToastr(message, this.translate.instant('AN_ERROR_OCCURRED'),this.toastrmsg);
    }

    // INFORMATION MESSAGES 2
    userWarning(message: string) {
        this.toastr.infoToastr(message, this.translate.instant('AN_ERROR_OCCURRED'),this.toastrmsg);
    }

    // INFORMATION MESSAGES 3
    formInvalidWarning() {
        this.toastr.infoToastr(this.translate.instant('FORM_IS_INVALID'), this.translate.instant('AN_ERROR_OCCURRED'),this.toastrmsg);
    }
}
