import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { Constant } from './shared/costant';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.SETTING,
        loadChildren: () => import('./components/setting/setting.module').then(mod => mod.SettingModule)
    },
    {
        path: Constant.APP_OBJECT.AUTH,
        loadChildren: () => import('./components/auth/auth.module').then(mod => mod.AuthModule)
    },
    {
        path: Constant.APP_OBJECT.PROFILE,
        loadChildren: () => import('./components/profile/profile.module').then(mod => mod.ProfileModule)
    },
    {
        path: Constant.APP_OBJECT.ADMIN,
        loadChildren: () => import('./components/admin/admin.module').then(mod => mod.AdminModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.MOTOR,
        loadChildren: () => import('./components/motor/motor.module').then(mod => mod.MotorModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.LIFE,
        loadChildren: () => import('./components/life/life.module').then(mod => mod.LifeModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.NON_MOTOR,
        loadChildren: () => import('./components/non-motor/non-motor.module').then(mod => mod.NonMotorModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.OPERATION_REPORTS,
        loadChildren: () => import('./components/operation-reports/operation-reports.module').then(mod => mod.OperationReportsModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.ACCOUNTING,
        loadChildren: () => import('./components/accounting/accounting.module').then(mod => mod.AccountingModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.FINANCE,
        loadChildren: () => import('./components/finance/finance.module').then(mod => mod.FinanceModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.FINANCE_REPORT,
        loadChildren: () => import('./components/finance-reports/finance-reports.module').then(mod => mod.FinanceReportsModule),
        canActivate: [AuthGuardService]
    },
    {
        path: Constant.APP_OBJECT.RECEIPT,
        loadChildren: () => import('./components/receipt/receipt.module').then(mod => mod.ReceiptModule),
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }