export class Api {

    static API_URL = {
        BPO: {
            PREFIX: 'bpos'
        },
        BUSSINESS_CLASS:{
            PREFIX: 'business_classes',
            COMMISION: 'business_class_commissions'
        },
        INSURANCE_PRODUCTS :{
            PREFIX: 'insurance_products',
            RATE: '/rate',
        },
        BUSSINESS_CATEGORY:{
            PREFIX: 'business_categorys'
        },
        BPO_INCENTIVES: {
            PREFIX: 'bpo_incentives',
        },
        CITY: {
            PREFIX: 'cities'
        },
        COVER: {
            PREFIX: 'covers'
        },
        COVER_NOTE: {
            PREFIX: 'cover_notes'
        },
        DISTRICT: {
            PREFIX: 'districts'
        },
        DEBIT_ADVISES: {
            PREFIX: 'debit_advises',
            PRE_SETTLEMENT: 'pre_settlement',
            SETTLEMENT: 'settlement',
            DIRECT: '/direct'
        },
        DEBIT_RECEIPT:{
            PREFIX : 'debit_receipts'
        },
        DEBIT_INCENTIVE:{
            PREFIX : 'debit_incentives'
        },
        DOCUMENT_TYPE:{
            PREFIX: 'document_types'
        },
        EMPLOYEE: {
            PREFIX: 'employees'
        },
        FUEL_TYPE: {
            PREFIX: 'fuel_types'
        },
        GET_TOKEN: 'auth/gettoken',
        COMPANY: {
            PREFIX: 'companies'
        },
        MAKE: {
            PREFIX: 'makes'
        },
        MODEL: {
            PREFIX: 'vehicle_models'
        },
        STAKEHOLDERS: {
            PREFIX: 'stakeholders',
            ADDRESS: 'stakeholder_addresses',
            ATTACHMENT: 'stakeholder_attachments'
        },
        PAGINATION: '/pagination/',
        PROVINCE: {
            PREFIX: 'provinces'
        },
        PRODUCT: {
            PREFIX: 'products'
        },
        PRODUCT_COVER: {
            PREFIX: 'product_covers'
        },
        RENEWAL: {
            PREFIX: 'renewals'
        },
        POLICY: {
            PREFIX: 'policies',
            ACTIVE : '/active/all',
            ATTACHMENT: 'policy_attachments'
        },
        POLICY_CLAIMS : {
            PREFIX: 'policy_claims'
        },
        USAGE: {
            PREFIX: 'usages'
        },
        USER: {
            PREFIX: 'users',
            RESET_PASSWORD: '/reset/password'
        },
        USER_ROLES: {
            PREFIX: 'user_roles',
        },
        UTILS: {
            PREFIX: 'utils',
            TITLE: '/titles',
            POLICY_TYPES: '/policy_types',
            PAYMENT_FREQUENCY: '/payment_frequencies',
            RECEIVED_TYPES: '/received_types',
            ADDRESS_TYPES: '/address_types',
            COVER_NOTE_REASON: '/cover_note_reasons'
        },
        QUOTATION: {
            PREFIX: 'quotations',
            COMPARISION: '/comparison/',
            COVER_TYPES: 'product_cover_types',
            ATTACHMENT: 'quotation_attachments'
        },
        QUOTATION_PREMIUM: {
            PREFIX: 'quotation_premiums'
        },
        REPORTS: {
            PREFIX: 'reports',
            BUSSINESS_OBJECT: '/business_object_count'
        }
    }
}
