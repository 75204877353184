import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Constant } from 'src/app/shared/costant';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  recoverForm: FormGroup
  clicked: boolean = false
  versionString : string = "";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private notification: NotificationService,
    private userService: UserService) { }

  ngOnInit() {
    this.setForm()
    this.versionString = Constant.APP.VERSION_STRING

  }

  setForm() {
    this.recoverForm = this.fb.group({
      emailCtrl: ['', Validators.compose([Validators.required, Validators.email])]
    })
  }

  recoverPassword() {
    if (this.recoverForm.invalid)
      return

    this.clicked = true
    let email = this.recoverForm.controls.emailCtrl.value
    let data = {
      "email": email
    }
    
    this.ngxLoader.start()
    this.userService.resetPassword(data).subscribe( res => {
      this.notification.showSuccessCustomMessage("Password reset success, Please check your mail inbox")
      this.ngxLoader.stop()
      this.backToLogin()
    }, error => {
      this.clicked = false
      this.notification.showWarningMessage("Server Error,  Please Try Again..")
      this.ngxLoader.stop()
    })
  }

  async backToLogin(){
    await new Promise(resolve => { setTimeout(resolve, 1500) });
    this.router.navigate([Constant.APP_URL.AUTH.LOGIN])
  }

}
