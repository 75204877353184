import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/shared/costant';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  versionString : string = "";

  constructor() { }

  ngOnInit() {
    this.versionString = Constant.APP.VERSION_STRING
  }

}
