import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DebitAdviseService } from 'src/app/services/debit-advise.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/services/notification.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as moment from 'moment';
import { MapType } from '@angular/compiler';
import * as _ from "lodash";
import { Constant } from 'src/app/shared/costant';
import { CommonFunction } from 'src/app/shared/common-function';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  DebitAdviceList: Array<any> = []
  DebitAdviceSummaryList: Array<any> = []
  dataTable: any;
  debitAdviseSearchFormGroup: FormGroup;
  totalQuotationRequest: number = 0;
  totalRenewal : number = 0;
  totalDebitNote : number = 0;
  totalCreditNote : number = 0;


  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Sales Vol'},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Forecast Vol'}
  ];

  constructor(
    private debitAdviceService: DebitAdviseService,
    private dashboardService: DashboardService,
    private chRef: ChangeDetectorRef,
    private ngxLoader: NgxUiLoaderService,
    private fb: FormBuilder,
    private notification: NotificationService) { }

  ngOnInit() {
    this.setForm()
    this.loadAllData()
    this.filterDebitNote()
    this.debitNoteGroupByInsuranceCompany()
  }

  setForm() {
    this.debitAdviseSearchFormGroup = this.fb.group({
      rangeCtrl: ['30']
    })
  }

  debitNoteGroupByInsuranceCompany() {

    let from = moment().subtract(365, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
    let to = moment().subtract(0, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
    let output = [];
    this.ngxLoader.start();
    let query = '?total_outstanding_amount={ "gte": 0}&debit_date={"lte": "'+to+'", "gte": "'+from+'"}&record_status=1,2,3'
    this.debitAdviceService.getDebitAdvise(query).subscribe(arr => {
     
      let debitList = _.filter(arr.allResult, function(c) {
        return c.debit_type == 'D';
      });
      let creditList = _.filter(arr.allResult, function(c) {
        return c.debit_type == 'C';
      });
      let debitResult = _.groupBy(debitList, 't_co_company.company_name');
      let creditResult = _.groupBy(creditList,'t_co_company.company_name');
      _.each(debitResult, function(arrayOfG) {
            let computedItem = {company_name: "", total_amount : 0, debit_count: 0, credit_count: 0};
            _.each(arrayOfG, function(item) {
                computedItem.company_name = item.t_co_company.company_name;
                computedItem.debit_count = arrayOfG.length;
              computedItem.total_amount += item.total_amount;
            });
            output.push(computedItem);
        });
  
      _.each(creditResult, function(arrayOfG) {
            let computedItem = {company_name: "", total_amount : 0, debit_count: 0, credit_count: 0};
            _.each(arrayOfG, function(item) {
                computedItem.company_name = item.t_co_company.company_name;
                computedItem.credit_count = arrayOfG.length;
              computedItem.total_amount += item.total_amount;
            });
            output.push(computedItem);
        });
          
        
        this.DebitAdviceSummaryList = output;

    }, error => {
      this.ngxLoader.stop();
      this.notification.errOccourred()
    })

  };

 

  filterDebitNote() {
    let option = this.debitAdviseSearchFormGroup.controls.rangeCtrl.value  || '30';
    let from; 
    let to;


    switch (option) {
      case '30':
        from = moment().subtract(30, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        to = moment().subtract(0, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        break;

      case '60':
        from = moment().subtract(60, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        to = moment().subtract(31, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        break;

      case '90':
        from = moment().subtract(90, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        to = moment().subtract(61, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        break;

      case '91':
        from = moment().subtract(365, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        to = moment().subtract(91, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
        break;

      default:
        break;
    }

    
    if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.ngxLoader.start();
    let query = '?debit_type=D&total_outstanding_amount={ "gte": 0}&debit_date={"lte": "'+to+'", "gte": "'+from+'"}&record_status=1,2,3'
    this.debitAdviceService.getDebitAdvise(query).subscribe(res => {
      this.ngxLoader.stop();
      this.DebitAdviceList = res.result;
      this.chRef.detectChanges();
      const table: any = $('#data_table');
      this.dataTable = table.DataTable();

    }, error => {
      this.ngxLoader.stop();
      this.notification.errOccourred()
    })


  }

  loadAllData(){
    this.ngxLoader.start()
    let from = moment().subtract(45, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
    let to = moment().subtract(0, "days").format(Constant.DATE_FORMAT.READ_DATE_FORMAT);
    let query = '?created_timestamp={"lte":"'+to+'", "gte": "'+from+'"}'
    this.dashboardService.getBussinessObjct(query).subscribe( bussinessObjctList => {

      this.totalQuotationRequest = bussinessObjctList.find(x => x.debit === 'Quotation Request').count;
      this.totalRenewal = bussinessObjctList.find(x => x.debit === 'renewal').count;
      this.totalDebitNote = bussinessObjctList.find(x => x.debit === 'debit').count;
      this.totalCreditNote = bussinessObjctList.find(x => x.debit === 'credit').count;

      this.ngxLoader.stop()
    }, error => {
      this.notification.errOccourred()
      this.ngxLoader.stop()
    })
  }

  
  getFloat(value: number, points: number=2) {
    value = CommonFunction.undefinedToZero(value)
    return parseFloat(value.toString()).toFixed(points)
  }


}
