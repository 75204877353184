import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, transition, query, style, animate } from '@angular/animations';
import { ThemeOptions } from '../theme-options';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('600ms ease', style({opacity: 1, transform: 'translateY(0)'})),
        ]),

        query(':leave', [
          animate('600ms ease', style({opacity: 0, transform: 'translateY(-20px)'})),
         ], { optional: true })
      ]),
    ])
  ]
})


export class MainNavComponent implements OnChanges {

    heading = 'Analytics Dashboard';
    subheading = 'This is an example dashboard created using build-in elements and components.';
    icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
 
  // @select('config') public config$: Observable<any>;

  constructor(public globals: ThemeOptions) {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

}
