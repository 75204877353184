import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class ThemeOptions {
  sidebarHover = false;
  toggleSidebar = false;
  toggleSidebarMobile = false;
  toggleHeaderMobile = false;
  toggleFixedFooter = false;

  public static ngxUiLoaderConfig: NgxUiLoaderConfig = {
    fgsSize: 50,
    fgsPosition: POSITION.centerCenter,
    fgsType: SPINNER.ballSpinClockwise, // background spinner type
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    pbThickness: 1, // progress bar thickness
  };
}
