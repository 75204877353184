export class CommonFunction {

  static objToDate(obj: any) {
    if (!obj)
      return ""

    return obj.year + '-' + obj.month + '-' + obj.day
  }

  static getRange(value: number) {
    let tmp = []
    for (let index = 1; index <= value; index++) {
      tmp.push(index)
    }

    return tmp
  }

  static generateIntArray(from: number, to: number, sign: string = '+') {

    let tmp = []
    if (sign == '-') {
      for (let index = from; index >= to; index--) {
        let t = {
          year: index
        }
        tmp.push(t)
      }
    } else {
      for (let index = from; index <= to; index++) {
        let t = {
          year: index
        }
        tmp.push(t)
      }
    }

    return tmp
  }

  static removeElementOfArray(arr: Array<any>, search: any, key: string) {
    let _tmp = [];

    arr.forEach(elem => {
      if (elem[key] != search[key]) {
        _tmp.push(elem);
      }
    });

    return _tmp;
  }

  public static getMaxValue(arr: Array<any>) {
    let tmp: Array<number> = []
    arr.forEach(elem => {
      tmp.push(elem.value)
    })
    return Math.max(...tmp)
  }

  public static removeDuplicatesOfArray(arr: Array<any>, key: string): Array<any> {

    let tmpArr = arr;
    let cleanArr = [];
    tmpArr.forEach(element => {
      if (cleanArr.findIndex(i => i[key] == element[key]) === -1) {
        cleanArr.push(element);
      }
    });

    return cleanArr;
  }

  public static intValueToBoolean(value: number): boolean {
    if (value == 1)
      return true;
    else
      return false;
  }

  public static booleanValueToInt(value: boolean): number {
    if (value)
      return 1;
    else
      return 0;
  }

  public static undefinedToZero(value: any): number {
    if (!value)
      return 0;
    else
      return value;
  }

  public static undefinedToString(value: any): string {
    if (!value)
      return '';
    else
      return value;
  }

  public static undefinedToEmptyArray(value: any): Array<any> {
    if (!value)
      return [];
    else
      return value;
  }

  public static undefinedToUnknownString(value: any): string {
    if (!value)
      return 'No-content';
    else
      return value;
  }

  public static ifDateNullSetToday(value: any) {
    if (!value)
      return new Date();
    else
      return value;
  }

  /* 
get substring of alias
*/
  public static getSubstringOfAlias(alias: string) {

    let value = "";
    if (alias != "") {
      let subArray = alias.split('-');
      if (subArray) {
        value = subArray[1];
      }
    }

    return value;
  }

  public static checkArrayKeyIsContainDuplicate(checkArr: Array<any>, key: string, search: string) {
    let notFound = true;
    let _search = search.substring(0, 3);
    checkArr.forEach(ele => {
      let _el = ele[key].substring(0, 3);
      if (_search == _el) {
        notFound = false;
      }
    })

    return notFound;
  }

  public static filterArrayByKey(arr: Array<any>, key: string, value: string) {
    let cleanArr = [];
    arr.forEach(element => {
      if (element[key] == value) {
        cleanArr.push(element);
      }
    });

    return cleanArr;
  }

  public static checkIsMinimum(minValue: number, maxValue: number) {
    if (maxValue > minValue)
      return true;
    else
      return false;
  }

  public static groupArrayByKey(arr: Array<any>, key: string) {
    let _dataArr = [];
    let first = "";
    let tmpArr = arr;

    arr.forEach(ele => {
      first = ele[key];
      let _find = first.substring(0, 3);

      tmpArr = tmpArr.filter(_tmp => {
        let st = _tmp[key].substring(0, 3);
        if (st == _find) {
          _dataArr.push(_tmp);

        } else {
          return _tmp;
        }
      });
    })

    return _dataArr;
  }

  public static getSearchItem(arr: Array<any>, search: any, value: any) {
    let find = null
    arr.forEach(res => {
      if (res[search] == value) {
        find = res
      }
    })

    return find
  }

  public static canEditDeleteUpdate(documentDate: Date, computerDate: Date) {
    let today = new Date();
    let documentDatePluseOne = new Date(documentDate);
    documentDatePluseOne.setDate(documentDatePluseOne.getDate() + 1);
    if(documentDatePluseOne > computerDate) {
      return true;
    }
    return false;
  }
}