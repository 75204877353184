import { SidebarComponent } from './sidebar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  MatExpansionModule,
  MatListModule,
  MatIconModule,
} from '@angular/material';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from '../Layout/Components/header/header.component';
import { UserBoxComponent } from '../Layout/Components/header/elements/user-box/user-box.component';
import { FooterComponent } from '../Layout/Components/footer/footer.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MegaMenuComponent } from '../Layout/Components/header/elements/mega-menu/mega-menu/mega-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchBoxComponent } from '../Layout/Components/header/elements/search-box/search-box.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    PerfectScrollbarModule,
    NgbModule,
    TranslateModule,
    AngularFontAwesomeModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  declarations: [
    // //LAYOUT COMPONENT
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    MegaMenuComponent,
    SidebarComponent,
    FooterComponent
  ],
  exports: [
    //LAYOUT COMPONENT
    HeaderComponent,
    UserBoxComponent,
    SearchBoxComponent,
    SidebarComponent,
    MegaMenuComponent,
    FooterComponent
  ],
})
export class SidebarModule { }
