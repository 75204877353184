// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * Build LOCAL environment
 */

// export const environment = {
//    production: false,
//    version: 'v1',
//    BASE_URL: 'http://localhost:3600',
//    app_name : "RapidX",
//    company_image : 'logo.png',
// };

/*
 * Build RapidX environment
 */

  export const environment = {
   production: true,
   BASE_URL: 'https://api.rapid-migration.com',
   version: 'v1',
   app_name : "RapidX",
   company_image : 'logo.png',
 };
 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
